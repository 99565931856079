import styled from 'styled-components';
import React from 'react';

interface Typography {
  H1: React.FC<TypographyProps>;
  H2: React.FC<TypographyProps>;
  H3: React.FC<TypographyProps>;
  Body: React.FC<TypographyProps>;
  LineItem: React.FC<TypographyProps>;
  ButtonText: React.FC<TypographyProps>;
  Label: React.FC<LabelProps>;
}

interface TypographyProps {
  children: React.ReactNode;
  textBlack?: boolean;
  fontBold?: boolean;
  displayInline?: boolean;
  style?: React.CSSProperties;
}

interface LabelProps extends TypographyProps {
  htmlFor?: string;
}

const T: Typography = {
  H1: styled.h1<TypographyProps>`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 1.75rem;
    /* line-height: 2rem; */
    color: ${props => (props.textBlack ? '#212121' : '#FFF')};
    margin: 0px;
  `,
  H2: styled.h2<TypographyProps>`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    /* line-height: 2rem; */
    color: ${props => (props.textBlack ? '#212121' : '#FFF')};
    margin: 0px;
  `,
  H3: styled.h3<TypographyProps>`
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    color: ${props => (props.textBlack ? '#212121' : '#FFF')};
    margin: 0px;
    padding: 0px;
  `,
  Body: styled.p<TypographyProps>`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
    color: ${props => (props.textBlack ? '#212121' : '#FFF')};
    margin: 0px;

    & a {
      color: rgb(79, 220, 255);
      text-decoration: none;
    }
    @media only screen and (min-width: 768px) {
      font-size: 1rem;
    }
  `,
  LineItem: styled.li<TypographyProps>`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
    color: ${props => (props.textBlack ? '#212121' : '#FFF')};

    a {
      color: rgb(79, 220, 255);
      text-decoration: none;
    }
  `,
  ButtonText: styled.p<TypographyProps>`
    color: ${props => (props.textBlack ? '#212121' : '#FFE76B')};
    font-family: 'Inter', sans-serif;
    font-weight: ${props => (props.fontBold ? 'bold' : 'normal')};
    font-size: 1rem;
    line-height: 0px;
    margin: 0px;
  `,
  Label: styled.label<TypographyProps>`
    display: ${props => (props.displayInline ? 'inline-block' : 'block')};
    position: relative;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: auto;
    color: ${props => (props.textBlack ? '#212121' : 'rgba(255,255,255,.8)')};
  `,
};

export default T;
