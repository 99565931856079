import React from 'react';
import Link from 'next/link';
import styled, { StyledComponent } from 'styled-components';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';

interface UType {
  StatusCircle: StyledComponent<'span', any, {}, never>;
  Counter: StyledComponent<'div', any, {}, never>;
  Flex: StyledComponent<'div', any, UFlexProps, never>;
  MobileFlex: StyledComponent<'div', any, UFlexProps, never>;
  OnlyMobile: StyledComponent<'div', any, {}, never>;
  OnlyDesktop: StyledComponent<'div', any, {}, never>;
  Link: StyledComponent<'a', any, {}, never>;
  NextLink: StyledComponent<typeof Link, any, {}, never>;
  LinkButton: StyledComponent<'a', any, {}, never>;
  HelpBlock: StyledComponent<'div', any, {}, never>;
}

interface UFlexProps {
  direction?: 'row' | 'column';
  type?:
    | 'space-between'
    | 'space-around'
    | 'center'
    | 'flex-start'
    | 'flex-end';
  alignment?: 'center' | 'flex-start' | 'flex-end';
  gap?: string | number;
}

const U: Partial<UType> = {};

U.StatusCircle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #42b65b;
  margin-right: 2px;
  display: inline-block;
`;

U.Counter = styled.div`
  background-color: white;
  border-radius: ${props => props.theme.borderRadius};
  padding: 2px 8px;
  display: inline-block;
  font-family: Roboto, sans-serif;
`;

U.Flex = styled.div<UFlexProps>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.type || 'space-between'};
  align-items: ${props => props.alignment || 'center'};
  gap: ${props => props.gap || '0px'};
`;

// Displays vertically on mobile
// Displays horizontally on web
U.MobileFlex = styled.div<UFlexProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.type || 'space-between'};
  align-items: start;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: ${props => props.alignment || 'center'};
  }
`;

U.OnlyMobile = styled.div`
  display: block;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

U.OnlyDesktop = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

U.NextLink = styled(Link)<{ href: string }>`
  border-radius: ${props => props.theme.borderRadius};
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  color: ${props => props.theme.brandYellow};
  padding: 8px;
  text-decoration: none;
  margin: 8px 0px;

  &:active {
    color: white;
  }

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

U.Link = styled.a`
  border-radius: ${props => props.theme.borderRadius};
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  color: ${props => props.theme.brandYellow};
  padding: 8px;
  text-decoration: none;
  margin: 8px 0px;

  &:active {
    color: white;
  }

  &:hover {
    cursor: pointer;
    color: white;
  }
`;

U.LinkButton = styled(U.Link)`
  color: #080808;
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.theme.brandYellow};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.35);
`;

U.HelpBlock = styled.div`
  cursor: pointer;

  a {
    color: #ffe76b;
  }

  details summary > * {
    display: inline;
  }

  details {
    color: white;
    font-family: 'Roboto', sans-serif;
    padding: 16px;
    border-radius: 2px;
    ${'' /* cursor: pointer; */}

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    ul {
      color: white;
      font-family: 'Roboto', sans-serif;
      padding: 16px;
      line-height: 175%;
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      padding-bottom: 0;

      .highlight {
        padding: 4px;
        border: 2px;
        background: rgba(0, 0, 0, 0.5);
        color: rgb(163, 237, 255);
      }
    }

    ol {
      li {
        margin-bottom: 12px;
      }
    }

    summary {
      color: white;

      h3 {
        display: inline;
        margin: 0;
      }
    }
  }
`;

export default U;

export const StyledDropdownRoot = styled(RadixDropdownMenu.Root)`
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  background: none;
`;

export const StyledDropdownTrigger = styled(RadixDropdownMenu.Trigger)`
  background: none;
  border: none;
  width: auto;
  margin: 0;
  padding: 0;
`;

export const StyledDropdownSubTrigger = styled(RadixDropdownMenu.SubTrigger)`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  color:   color: rgba(0, 0, 0, 0.95);
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const StyledDropdownContent = styled(RadixDropdownMenu.Content)`
  background-color: white;
  right: 0;
  padding: 0.25rem;
  border-radius: 4px;
  width: 8rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
`;

export const StyledDropdownSubContent = styled(RadixDropdownMenu.SubContent)`
  background-color: white;
  right: 0;
  padding: 0.25rem;
  padding-right: 0.5rem;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
`;

export const StyledDropdownItem = styled(RadixDropdownMenu.Item)`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.95);
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem;
  display: flex;
  align-item: end;
  gap: 4px;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const StyledDropdownItemIndicator = styled(
  RadixDropdownMenu.ItemIndicator,
)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
